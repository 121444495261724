import { Customer } from "./Customer";
import { Employee } from "./Employee";
import { Machine, MachineVariantIdentifier } from "./Machine";
import { Order } from "./Order";
import { RentalOrder } from "./RentalOrder";

export enum MachineCounterType {
    OPERATING_HOURS = "OPERATING_HOURS",
    HECTARE = "HECTARE",
    DRUM_HOURS = "DRUM_HOURS",
    KILOMETER = "KILOMETER",
    LOADS = "LOADS",
    BALES = "BALES",
    DIESEL = "DIESEL",
}

type MachineCounterTypeLabel = {
    value: MachineCounterType;
    label: string;
};

export const MACHINE_COUNTER_TYPE_LABELS: MachineCounterTypeLabel[] = [
    { value: MachineCounterType.OPERATING_HOURS, label: "Betriebsstunden" },
    { value: MachineCounterType.HECTARE, label: "Hektar" },
    { value: MachineCounterType.DRUM_HOURS, label: "Trommelstunden" },
    { value: MachineCounterType.KILOMETER, label: "Kilometer" },
    { value: MachineCounterType.LOADS, label: "Fuhren" },
    { value: MachineCounterType.BALES, label: "Ballen" },
    { value: MachineCounterType.DIESEL, label: "Diesel (Liter)" },
];

export function isMachineCounterType(type: any): type is MachineCounterType {
    if (!type) {
        return false;
    }
    return Object.values(MachineCounterType).includes(type);
}

export enum MachineCounterTrackingSource {
    MACHINE = "MACHINE",
    PRICE_BLOCK = "PRICE_BLOCK",
}

export type MachineCounterTrackingSourceMachine = {
    type: MachineCounterTrackingSource.MACHINE;
    machineId: Machine["id"];
};

export type MachineCounterTrackingSourcePriceBlock = {
    type: MachineCounterTrackingSource.PRICE_BLOCK;
    machineId: null;
};

export type AnyMachineCounterTrackingSource =
    | MachineCounterTrackingSourceMachine
    | MachineCounterTrackingSourcePriceBlock;

export class MachineCounterTracking {
    public id: string;
    public orderId: Order["id"] | null;
    public orderRunId: string | null;
    public machineVariants: MachineVariantIdentifier[];
    public rentalOrderId: RentalOrder["id"] | null;
    public employeeId: Employee["id"];
    public type: MachineCounterType;
    public customerId: Customer["id"] | null;
    /**
     * ISO Datetime
     */
    public startDate: string;
    public startValue: number;
    /**
     * ISO Datetime
     */
    public endDate: string | null;
    public endValue: number | null;

    public source: AnyMachineCounterTrackingSource;

    public archived: boolean;

    constructor(initialValues?: Partial<MachineCounterTracking>) {
        this.id = initialValues?.id ?? "";
        this.orderId = initialValues?.orderId ?? null;
        this.orderRunId = initialValues?.orderRunId ?? null;
        this.machineVariants = initialValues?.machineVariants ?? [];
        this.rentalOrderId = initialValues?.rentalOrderId ?? null;
        this.employeeId = initialValues?.employeeId ?? "";
        this.customerId = initialValues?.customerId ?? null;
        this.type = initialValues?.type ?? MachineCounterType.OPERATING_HOURS;
        this.startDate = initialValues?.startDate ?? new Date().toISOString();
        this.startValue = initialValues?.startValue ?? 0;
        this.endDate = initialValues?.endDate ?? null;
        this.endValue = initialValues?.endValue ?? null;
        this.source = initialValues?.source ?? {
            type: MachineCounterTrackingSource.MACHINE,
            machineId: initialValues?.source?.machineId ?? "",
        };
        this.archived = initialValues?.archived ?? false;
    }
}
